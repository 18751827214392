<template>
  <div>
    <template>
      <!-- First Row -->
      <b-card>
        <b-row>
          <h4 class="pl-1 pb-1">Numero de apuesta: {{ $route.params.id }}</h4>
          <b-table md="9" :items="dataItems" :fields="fields" class="scroll" responsive>
            <template #cell(referenceId)="data">
              <b-button variant="outline-primary">{{ data.item.referenceId }}</b-button>
            </template>
            <template #cell(ticketStatus)="data">
              <span v-if="data.item.ticketStatus == true"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;">
                ACTIVO
              </span>
              <span v-else
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;">
                INACTIVO
              </span>
            </template>
          </b-table>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <h3 class="pl-1 pb-1">{{ $t("labels.ticketdetail") }}</h3>
          <b-table md="9" :items="dataSelections" :fields="detailFields" class="scroll" responsive>
            <template #cell(juego)="data">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                :style="data.item.juego == true ? { color: '#28c76f' } : { color: 'gray' }" class="bi bi-circle-fill"
                viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8" />
              </svg>
            </template>
            <template #cell(img)="data">
              <!-- {{data.item.img}} -->
              <img :src="data.item.img" width="50%" />
              <!-- <image-table-field :url="data.item.img"></image-table-field> -->
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency({ symbol: "" }) }}
            </template>
            <template #cell(finalBonusAmount)="data">
              {{ data.item.finalBonusAmount | currency({ symbol: "" }) }}
            </template>
            <template #cell(created)="data">
              {{ data.item.created | moment }}
            </template>
          </b-table>
        </b-row>
        <div class="d-flex mt-2">
          <b-button type="button" variant="outline-secondary" to="/apps/reports/ticket-sports-live/betsPlaced">
            {{ $t("buttons.back") }}
          </b-button>
          <span v-if="typeUser === 'Agent'">
            <span v-if="dataTicket.allowConfirm == true">
              <span v-if="
                dataTicket.allowConfirm === true &&
                dataTicket.allStarted === true
              ">
                <b-button variant="warning">
                  No se puede confirmar ticket, todos los eventos iniciados
                </b-button>
              </span>
              <span v-else-if="dataTicket.eventStarted === true">
                <b-button variant="warning" @click="confirmTicket($route.params.id, dataTicket.amount)">
                  Confirmar ticket con eventos inicializados
                </b-button>
              </span>
              <span v-else-if="dataTicket.eventStarted === false">
                <button variant="success" @click="confirmTicket($route.params.id, dataTicket.amount)">
                  Confirmar ticket
                </button>
              </span>
            </span>
          </span>
        </div>
      </b-card>
      <!-- Form Actions -->
    </template>
  </div>
  <!-- <div v-else>
        <template>
           
            <b-row>
                hi there! 2
            </b-row>
        </template>
    </div> -->
</template>

<script>
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import betsPlacedStoreModule from "./betsPlacedStoreModule";
import userStoreModule from "@/views/apps/user/userStoreModule";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
  },
  data() {
    return {
      fields: [
        { label: "Usuario", key: "user" },
        { label: "Id", key: "referenceId" },
        { label: "Monto de Débito", key: "debit_amount" },
        { label: "Monto de Crédito", key: "credit_amount" },
        { label: "Status", key: "ticketStatus" },
      ],
      detailFields: [
        { label: "Tipo", key: "referencia" },
        { label: "Seleccion", key: "apuesta" },
        { label: "Liga ", key: "liga" },
        { label: "Deporte", key: "deporte" },
        { label: "Valor", key: "valor" },
        { label: "Juego", key: "juego" },
        { label: "Equipo A", key: "equipoa" },
        { label: "Equipo B", key: "equipob" },
      ],
      dataItems: [],
      dataTicket: [],
      dataSelections: [],
      LIVE_SPORT_STORE_MODULE_NAME: "app-live-sport"
    };
  },
  destroyed() {
    // unregister module store
    if(store.hasModule(this.LIVE_SPORT_STORE_MODULE_NAME)){
      store.unregisterModule(this.LIVE_SPORT_STORE_MODULE_NAME);
    }
  },
  created() {
    if (!store.hasModule(this.LIVE_SPORT_STORE_MODULE_NAME))
      store.registerModule(this.LIVE_SPORT_STORE_MODULE_NAME, betsPlacedStoreModule);
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;
    this.userNameDestination = JSON.parse(
      localStorage.getItem("userData")
    ).userName;
    this.typeUser = JSON.parse(localStorage.getItem("userData")).typeUser;
    const whitelabelId = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
    store
      .dispatch("app-live-sport/fetchSw3", { id: router.currentRoute.params.id, whitelabel : whitelabelId })
      .then((response) => {
        this.dataItems.push(response.data[0]);
        let ticketDetail = response.data[0].bets;

        ticketDetail.forEach((e) =>
          this.dataSelections.push({
            referencia: e.referencia,
            apuesta: e.extra_data.apuesta,
            liga: e.extra_data.liga,
            deporte: e.extra_data.deporte,
            valor: e.seleccion_ref.price,
            juego: e.islive,
            equipoa: e.equipoa,
            equipob: e.equipob,
            text_info: e.extra_data.text_info,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  },
  methods: {
    confirmTicket(ticketId, amount_real) {
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const userId = this.idUserSelect;
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency;

      const params = {
        currency: currency,
        amount: amount_real,
        userId: userId,
        ticket: ticketId,
      };
      const result = axios
        .post(`${VUE_APP_URL}/confirmDetail`, params)
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: r.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => console.log(error.response));
      return result;
    },
  },
  mounted() {
    const VUE_APP_URL = process.env.VUE_APP_URL;
    const ticketId = router.currentRoute.params.id;
    const currency = this.$store.state.whitelabelCurrencyNabvar.currency;
    const params = {
      // apiMode: true,
      ticketId: ticketId,
      currency: currency,
      userId: this.idUserSelect,
      whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
    };
    const result = axios
      .post(`${VUE_APP_URL}/showTicketsDetail`, params)
      .then((r) => {
        this.dataTicket = r.data;
      })
      .catch((error) => console.log(error.response));
    return result;
  },
};
</script>

<style>
</style>
